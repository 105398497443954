<template>
 
 <div style="margin-top: 100px">
    <BasePageBreadCrumb :pages="pages" :title="` Enviar arquivos`"/>

<div class="container-fluid qsuite-c-fluid-0" >

       <div class="col-12">
           <div class="row">
               <div class="col-12 col-md-12 col-lg-7">
                   <div class="card card-bg-trans">
                       <div class="card-body pt-2 px-0">
                           <form @submit="save" class="px-2 needs-validation" novalidate="">
                               <div class="form-body">
                                   <div class="row">
                                      
                                       <div class="col-12 pb-2 pt-2 ">
                                           <label>Empreendimento </label>
                                           <div class="input-group">
                                               <div class="input-group-prepend">
                                                   <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                                               </div>
                                               <input v-model="enterpriseSearch" type="text" class="form-control" placeholder="Procurar" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                           </div>
                                       </div>
                                       <div class="col-12">
                                           <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-1">
                                               <div class="col-12">
                                                   <div class="row">
                                                       <div v-for="item in enterprises" class="col-12 col-md-6 col-lg-4">
                                                           <div class="custom-control custom-radio">
                                                               <input :value="item.id" type="radio" @click="fetchEnterpriseFiles(item.id)" v-model="enterpriseFile.enterprise_id" :id="`enterprise_${item.id}`" name="enterprise_id" class="custom-control-input">
                                                               <label class="custom-control-label check-label" :for="`enterprise_${item.id}`">{{item.name}}</label>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="col-12 pb-2 mt-4 d-none">
                                           <label>Corretores</label>
                                           <div class="input-group">
                                               <div class="input-group-prepend">
                                                   <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                                               </div>
                                               <input v-model="brokerSearch" type="text" class="form-control" placeholder="Procurar" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                           </div>
                                       </div>
                                       <!-- <div class="col-12">
                                           <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-1">
                                               <div class="col-12">
                                                  
                                                   <div v-for="item in brokers" class="custom-control custom-checkbox">
                                                       <input type="checkbox" :checked="item.checked" @click="() => brokerChangedChange(item)" class="custom-control-input" :id="`broker${item.id}`">
                                                       <label class="custom-control-label check-label" :for="`broker${item.id}`">{{item.first_name}}</label>
                                                   </div>
                                                   
                                               </div>
                                           </div>
                                       </div> -->
                                       
                                       <div class="col-12">
                                           <div class="form-group pt-3">
                                               <label for="enterprise">Tipo de envio</label>
                                               <select @change="deleteImages();onlyAllowImages()" required v-model="enterpriseFile.enterprises_files_types_id" class="custom-select mr-sm-2" id="enterprise">
                                                   <option value="0" selected="">Selecione</option>
                                                   <option value="1">Tabela comercial do mês</option>
                                                   <option value="2">Kit marketing</option>
                                                   <option value="3">Imagens e plantas</option>
                                                   <option value="4">Documentos</option>
                                                   <option value="5">Vídeos</option>
                                               </select>
                                           </div>
                                       </div>                                      
                                       <div class="col-12 pt-4" v-if="enterpriseFile.enterprises_files_types_id!=5"> 
                                           <div class="form-group">
                                               <div class="input-group mb-3">
                                                   <div class="input-group-prepend">
                                                       <span class="input-group-text">Upload</span>
                                                   </div>                                                 
                                                   <div class="custom-file">
                                                       <input required type="file" multiple class="custom-file-input" @change="setFile" id="inputGroupFile01">
                                                       <label class="custom-file-label" for="inputGroupFile01">{{file_unit.file.name}}</label>
                                                   </div>        
                                               </div>
                                           </div>   

                                    
                                      
                                           <!-- <div v-if="enterpriseFile.files" class="images mt-5" v-for="(file, index) in enterpriseFile.files">
                                                <div class="row row-cols-1 row-cols-md-3">
                                                    <div class="col mb-4">
                                                        <div class="card h-100">
                                                            <img width="200px"   src="" :id="'file'+index">
                                                            <div class="card-body">
                                                                <div class=" pt-4">
                                                                    <div class="form-group">
                                                                        <label>Descrição</label>
                                                                        <input required v-model="file.description" class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-6 ml-auto">
                                                                    <div class="form-group">
                                                                        <label>Agendar para excluir</label>
                                                                        <input v-model="file.date_hour_delete" type="datetime-local" class="form-control">
                                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Será excluído totalmente da plataforma</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                                                                                
                                            </div>                                        -->
                                       </div>
                                   </div>
                                <div class="pt-3" v-if="enterpriseFile.enterprises_files_types_id==5">
                                    <!-- <button type="button" class="btn btn-primary mb-3 d-block" @click="criarLink()">criar link</button>  -->
                                    <div v-for="(youtubelink, id) in youtubelinks" :key="id" >
                                      <label>DESCRIÇÃO</label>
                                             <input
                                                type="text"
                                                class="form-control w-100 col-12"
                                                v-model="descriptions[id]"
                                            />   
                                    <div class="d-flex mt-4">                                      
                                        <input type="text" class="form-control w-70 mt-2 mb-2" placeholder="Link do YouTube" v-model="youtubelinks[id]" />
                                         <!-- <button type="button" class="btn mt-2 mb-2 btn-secondary" @click="apagarLink(id)">apagar</button> -->
                                     </div>
                                 
                                    </div>
                                </div>
                                <div class="row d-flex justify-content-start mt-5" v-if="enterpriseFile.enterprises_files_types_id!=5">
                                        <div class="col-md-4 pb-2 mt-5" v-if="enterpriseFile.files"  v-for="(file, index) in enterpriseFile.files">
                                            <div style="width: 150px">
                                                <img style="max-height: 100px; width: 150px; object-fit: contain;"  src="" :id="'file'+index">
                                            </div>
                                            <div class="row px-2">
                                            <input
                                                type="text"
                                                class="form-control col-9 mt-2"
                                                v-model="file.description"
                                            />
                                            <b
                                                class="col-2 d-flex justify-content-center align-items-center" style="cursor:pointer"
                                                @click="remove(index)"
                                            >
                                                <i class="fas fa-times text-danger"></i>
                                            </b>
                                            </div>
                                        </div>
                                </div>
                               </div>
                               <div class="form-actions">
                                   <div class="text-right">
                                       <button type="reset" class="btn btn-dark">Cancelar</button>
                                       <button type="submit" class="btn btn-info">Enviar</button>
                                   </div>
                               </div>
                           </form>
                       </div>
                   </div>
               </div>

               <div class="col-12 col-md-12 col-lg-5 p-0">
                   <div class="col-12">
                       <div class="card-list py-0"  v-if="enterpriseSelected">
                           <div class="card-body-list py-0">
                               <div class="col-12 p-0 align-self-center">
                                   <div class="col-12">
                                       <div class="row">
                                           <div v-show="enterpriseSelected.image" class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                               <img :src="enterpriseSelected.image" alt="user" class="rounded-circle card-body-list-img">
                                           </div>
                                           <div class="col-7 col-md-7 col-lg-9 p-0 order-3 align-self-center">
                                               <h6 class="card-body-list-title d-none d-lg-block">{{enterpriseSelected.name}}</h6>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div class="col-12 text-center pt-2">
                           <h3 style="font-size: 12px">ARQUIVOS ENVIADOS</h3>
                       </div>
                       <div class="overflow-auto" style="height:335px">
                        <div v-for="item in all_enterprises_files" :key="item.id" class="card-list">
                            <div class="card-body-list">
                                <div class="col-12 p-0 align-self-center">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-7 col-md-7 col-lg-9 p-0 order-3 align-self-center">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 col-lg-9 align-self-center pad-content-list">
                                                            <h6 class="font-10">{{item.created_at}}</h6>
                                                            <h6 class="card-body-list-title py-1" style="text-transform:uppercase">{{item.description}}</h6>
                                                            <h6 class="font-12"><a style="text-decoration:underline;" :href="item.path_name" target="_blank">{{item.name}}</a></h6>
                                                        </div>
                                                        <div class="col-8 col-md-4 col-lg-3 align-self-center pad-content-list">
                                                            <h6 class="apto">{{item.brokers.length}}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 col-md-2 col-lg-3 align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                                <h6 class="font-10">{{item.date_hour_delete}}</h6>
                                                <a @click="deleteFileEnterprise(item)" href="javascript:void(0);" class="btn btn-danger text-white rounded-circle btn-circle font-16"><i class="far fa-trash-alt"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       </div>
                    
                   </div>

               </div>
           </div>
       </div>
   </div>
 </div>
 

</template>
  <script>
  import EnterpriseFileService from "@/services/resources/EnterpriseFileService";
  import BrokerService from "@/services/resources/BrokerService";
  import EnterpriseService from "@/services/resources/EnterpriseService";
  const serviceEnterpriseFile = EnterpriseFileService.build();
  const brokerService = BrokerService.build();
  const service = EnterpriseService.build();
  
  export default {
    data() {
      return {
        enterpriseSearch: '',
        all_enterprises: [],
        enterprises: [],
        brokerSearch: '',
        youtubelinks: [''],
        descriptions: [""],
        all_brokers: [],
        brokers: [],
        all_enterprises_files: [],
        pagination: {
             totalPages: 1,
             currentPage: 1,
             perPage: 10,
             itens: 0
        },
        pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            },
            {
                name: 'imóveis',
                to: 'Enterprise'
            }
        ],
 
        enterpriseFile: {
                   
                    enterprises_files_types_id: 0 ,
                    enterprise_id: null ,
                    brokers: [
                        {
                            id: 0,
                            checked: false
                        }
                    ],
                    files: []
        },
        file_unit: {
            description: null,
            date_hour_delete: null ,
            file: {
                name: "ANEXAR ARQUIVO"
            }
        },
        files: []
      };
    },
    methods: {
    
    deleteFileEnterprise(item){
        let data = {
            id: item.id
        };

        serviceEnterpriseFile
        .destroy(data)
        .then(resp => {
            this.$bvToast.toast('Arquivo excluído com sucesso!', {
                title: 'Excluir pedido',
                autoHideDelay: 5000,
                type: 'success'
            })
            this.fetchEnterpriseFiles();

        })
        .catch(err => {
            this.$bvToast.toast('Seu pedido não pode ser excluído.', {
                title: 'Excluir pedido',
                autoHideDelay: 5000,
                type: 'danger'
            })
        })

    },
    apagarLink(id){
        this.youtubelinks.splice(id, 1)
        this.descriptions.splice(id, 1)
    },
    criarLink(){
       // this.youtubelinks[this.youtubelinks.length]='' 
       this.$set(this.youtubelinks, this.youtubelinks.length, '')
       this.$set(this.youtubelinks, this.descriptions.length, '')
    },
    setFile(eve) {       
        for (let id = 0; id <  eve.target.files.length; id++) {       
            this.enterpriseFile.files[this.enterpriseFile.files.length] = 
            { file: eve.target.files[id], name: eve.target.files[id].name, description: "", date_hour_delete: ""}
        }
      

        this.enterpriseFile.files.forEach((file, id) => {
            var reader = new FileReader();
            reader.onload = function (eve2) {               
                $('#file'+id).attr('src', eve2.target.result);
            }          
            reader.readAsDataURL(file.file);
        })
        this.file_unit.file.name = eve.target.files[0].name;
    },
    reshowFiles() {
        this.enterpriseFile.files.forEach((file, id) => {
            var reader = new FileReader();
            reader.onload = function (eve2) {               
                $('#file'+id).attr('src', eve2.target.result);
            }          
            reader.readAsDataURL(file.file);
        })
    },
    deleteImages() {
        this.file_unit.file.name = "";
      this.enterpriseFile.files.length = 0
    },
    onlyAllowImages() {
        if ($("#enterprise").val() == 3) {
            document.getElementById("inputGroupFile01").setAttribute("accept", "image/*");
        } else {
            document.getElementById("inputGroupFile01").removeAttribute("accept");
        }
    },
    remove(id) {
        this.enterpriseFile.files.splice(id, 1);
        this.reshowFiles()
    },
    save(e) {
        e.preventDefault();
        let data = new FormData();
        let brokersTreated = [];
        
        
       this.all_brokers.forEach(item => {
            //brokersTreated[brokersTreated.length]=item.id 
            brokersTreated[brokersTreated.length]=item 
       });

        if(this.enterpriseFile.enterprises_files_types_id!=5){        

            data.append("enterprises_files_types_id", this.enterpriseFile.enterprises_files_types_id);
            data.append("brokers", JSON.stringify(brokersTreated));
            data.append("enterprise_id", this.enterpriseFile.enterprise_id);
        
            for (let i =0; i < this.enterpriseFile.files.length; i++) { 
                data.append("file"+i,  this.enterpriseFile.files[i].file),
                data.append("description"+i, this.enterpriseFile.files[i].description),
                data.append("date_hour_delete"+i, this.enterpriseFile.files[i].date_hour_delete ?? '')           
            }
            
            data.append("quantity",  this.enterpriseFile.files.length)
        }else{
            for (let id =0; id < this.youtubelinks.length; id++) { 
                data.append("youtubelink"+id,  this.youtubelinks[id]) 
                  data.append("description"+id, this.descriptions[id]) 
            }
            data.append("enterprises_files_types_id","5")   
          
            data.append("quantity",  this.youtubelinks.length) 
            data.append("enterprise_id", this.enterpriseFile.enterprise_id);  
            data.append("brokers", JSON.stringify(brokersTreated));

        }
        

        serviceEnterpriseFile
        .create(data)
                    .then(resp => {
                     
                        this.$bvToast.toast('Arquivo adicionado com sucesso!', {
                            title: 'Arquivo adicionado',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                        this.enterpriseFile.files.length = 0
                        this.file_unit.description = null;
                        this.file_unit.date_hour_delete = null;
                        this.file_unit.file.name = 'ANEXAR ARQUIVO';

                        this.fetchEnterpriseFiles();

                        this.brokers = this.brokers.map(item => {
                            return {
                                ...item,
                               
                            }
                        });
                      
                 
                    })
                    .catch(err => {
                       if (err.name != null && err.name == "Error") {
                            this.$bvToast.toast('Erro ao criar arquivos! Tente novamente ou entre em contato com o suporte', {
                                title: 'Arquivos não adicionados',
                                autoHideDelay: 5000,
                                type: 'error'
                            })
                        }
                    }).finally(() => {
                       
                    }) ;
    },
    brokerChangedChange(value) {
        let copyBrokers = [];

        if((+value.id == 0) && !value.checked) {
            this.brokers.map(item => {
                copyBrokers.push({...item, checked: true});
            })       
        } else if ((+value.id == 0) && value.checked) {
            this.brokers.map(item => {
                copyBrokers.push({...item, checked: false});
            })       
        } else {
            this.brokers.map(item => {
                if(item.id === value.id) {
                    copyBrokers.push({...item, checked: !item.checked});
                } else {
                    copyBrokers.push(item);
                }
            })       
        }

        this.brokers = copyBrokers;
    },
      toPage(page) {
          this.pagination.currentPage = page;
          this.fetchEnterprises();
      },
      perPage(qtd) {
          this.pagination.currentPage = 1;
          this.pagination.perPage = qtd;
          this.fetchEnterprises();
      },
      fetchBrokers() {
        let data = {all: 1};
        brokerService.search(data)
        .then(resp => {
            resp = resp.map(item => {
                return {
                    ...item,
                    checked: false
                }
            })
              this.brokers = resp;
              this.all_brokers = resp;
            })
            .catch(err => {
          
            })
      },
      fetchEnterpriseFiles(params = null) {
        let data = {enterprise_id: params} ?? {all: 1};
  
    data["todo_arquivos"] = "sim"

        serviceEnterpriseFile.search(data)
        .then(resp => {
            let createdAtTreated, dateHourDeleteTreated;
   
            this.all_enterprises_files = resp.map(item => {
                           
                createdAtTreated = item.created_at.split('T')[0];
                
                createdAtTreated = createdAtTreated.split('-');
                createdAtTreated = `${createdAtTreated[2]}/${createdAtTreated[1]}/${createdAtTreated[0]}`
                createdAtTreated = `${createdAtTreated} | ${item.created_at.split('T')[1].slice(0, 8)}`

                if(item.date_hour_delete) {
                    dateHourDeleteTreated = item.date_hour_delete.split('T')[0];
                    dateHourDeleteTreated = dateHourDeleteTreated.split('-');
                    dateHourDeleteTreated = `${dateHourDeleteTreated[2]}/${dateHourDeleteTreated[1]}/${dateHourDeleteTreated[0]}`
                } else {
                    dateHourDeleteTreated = '';
                }

                return {
                    ...item,
                    created_at: createdAtTreated,
                    date_hour_delete: dateHourDeleteTreated
                }
            });
        })
            .catch(err => {
          
            })

      },
      fetchEnterprises(){

          service.search()
            .then(resp => {
              this.enterprises = resp;
              this.all_enterprises = resp;
            })
            .catch(err => {
              console.log(err)
            })
      },
      goEdit(id){
          this.$router.push({name: 'EnterpriseEdit', params: {id: id}})
      }
  
    },
    mounted() {
        this.fetchEnterprises();
        this.fetchBrokers();
    },
    computed: {
        
        enterpriseSelected() {

            let enterpriseTreated = null;

            this.all_enterprises.forEach(item => {
                if(item.id === this.enterpriseFile.enterprise_id) {
                    enterpriseTreated = item;
                }
            });

            return enterpriseTreated;
        }
    },
    watch: {
        'enterpriseFile.enterprises_files_types_id': function() {

            let params = {
                all: 1,
                enterprises_files_types_id: this.enterpriseFile.enterprises_files_types_id ?? '',
                enterprise_id: this.enterpriseFile.enterprise_id ?? '',
                brokers: '',
            }

          //  this.fetchEnterpriseFiles(params);
        },  

        'enterpriseFile.enterprise_id': function() {
   
            let params = {
                all: 1,
                enterprises_files_types_id: this.enterpriseFile.enterprises_files_types_id ?? '',
                enterprise_id: this.enterpriseFile.enterprise_id ?? '',
                brokers: '',
            }

           // this.fetchEnterpriseFiles(params);
        },  
        brokers: function() {
          

            let brokersTreated = [];

            this.brokers.forEach(item => {
                if(item.checked && item.id) {
                    brokersTreated.push(item);              
                }
            
            });

            let params = {
                all: 1,
                enterprises_files_types_id: this.enterpriseFile.enterprises_files_types_id ?? '',
                enterprise_id: this.enterpriseFile.enterprise_id ?? '',
                brokers: JSON.stringify(brokersTreated),
            }

            if(
                this.enterpriseFile.enterprises_files_types_id ||
                this.enterpriseFile.enterprise_id || brokersTreated.length
            ) {
                this.fetchEnterpriseFiles(params);
            }
        },
              
        enterpriseSearch() {
            let enterprisesFiltered = [];
            if(!this.enterpriseSearch.length) {
                this.enterprises = this.all_enterprises;
                return;
            }
            this.all_enterprises.forEach(enterprise => {
                if(enterprise.name.toLowerCase().includes(this.enterpriseSearch)) {
                    enterprisesFiltered.push(enterprise);
                }
            });

            this.enterprises = enterprisesFiltered;
        },
        brokerSearch() {
            let brokersFiltered = [];
            if(!this.brokerSearch.length) {
                this.brokers = this.all_brokers;
                return;
            }
            this.all_brokers.forEach(broker => {
                if(broker.first_name.toLowerCase().includes(this.brokerSearch)) {
                    brokersFiltered.push(broker);
                }
            });

            this.brokers = brokersFiltered;
        }
    }
  };
  </script>
  <style scoped lang="scss">

  </style>